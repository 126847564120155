import styled, { css } from 'styled-components';
import { BusinessListCard } from '../../components/BusinessListCard/BusinessListCard';
import { useEffect, useState } from 'react';
import LeafletMap from '../../components/LeafletMap/LeafletMap';
import { BannerItem, CompanyListItem, CompanySearchFilters } from '../../types/api';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getSearchCompanies } from '../../services/company.service';
import { Pagination } from '../../components/Pagination/Pagination';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import { AdCard } from '../../components/AdCard/AdCard';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AdditionalFilters } from '../../components/SearchBar/AdditionalFilters/AdditionalFilters';
import { InputItem } from '../../components/SearchBar/InputItem';
import searchIcon from '@mui/icons-material/Search';
import { getSearchCompaniesList } from '../../services/company.service';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { RelatedCities } from '../../components/CategoryTopics/RelatedCities';

const BusinessListPage = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
`;
const CardContainer = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1254px;
        padding: 0 16px;
        gap: 2%;
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: 64.5% auto;
            gap: 16px;
        `}
    `}
`;

const Hero = styled.div`
    ${(props) => css`
        width: 59%;
        min-height: calc(100vh - 523px);
        margin-bottom: 32px;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;
const SideBar = styled.div`
    ${(props) => css`
        width: 39%;
        &.hide {
            display: none;
        }
        &.mobile-size {
            .leaflet-container {
                margin-top: -25px;
                height: 454px;
            }
        }

        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            order: 1;
        `}
    `}
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const AdsWrapper = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        padding: 16px;
        border: 1px solid ${(props) => props.theme.colors.outlineGrey};
        margin-bottom: 30px;
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;

const AdCardStyled = styled(AdCard)`
    flex: 1;
`;

const Wrap = styled.div`
    ${(props) => css`
        position: sticky;
        top: 88px;
        /* This might be needed when gate is not active. */
        /* top: 75px; */
        /* width: 42.3vw; */
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 16px;
            width: 100%;
        `}
    `}
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
`;
const FilterWrap = styled.div`
    ${(props) =>
        css`
            width: 100%;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
        `}
`;
const FilterButton = styled.div`
    ${(props) =>
        css`
            padding: 15px 24px;
            color: ${props.theme.colors.brandRed};
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            width: fit-content;
        `}
`;
const ListMapWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
`;
const ListMapButton = styled.div`
    ${(props) =>
        css`
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            color: ${props.theme.colors.secondaryBlack};
            padding: 13px 0;
            cursor: pointer;
            border-radius: 8px 8px 0 0;
            width: calc(50% - 4px);
            text-align: center;
            background-color: ${props.theme.colors.greyBg};
            &.active {
                background-color: ${props.theme.colors.yellow};
                &:after {
                    content: '';
                    height: 2px;
                    display: flex;
                    width: 100%;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    top: 4px;
                    background-color: ${(props) => props.theme.colors.green};
                }
            }
        `}
`;

const Line = styled.div`
    ${(props) =>
        css`
            height: 1px;
            width: 100%;
            background-color: ${props.theme.colors.outlineGrey};
        `}
`;

const HeaderWrapper = styled.div`
    display: flex;
    margin-top: 16px;
    justify-content: center;
    width: 1254px;
    justify-content: space-between;
`;

const Header = styled.h1`
    margin: 0;
    font-family: 'Albert-Regular';
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    padding: 0 16px;
`;

const InputWrap = styled.form`
    ${(props) => css`
        width: 353px;
        display: flex;
        &.press-releases {
            width: 504px;
        }
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 16px;
            width: 100%;
        `}
    `}
`;

const Button = styled.button`
    ${(props) => css`
        background-color: ${props.theme.colors.black};
        border-radius: 0px 8px 8px 0px;
        border: none;
        min-width: 48px;
        font-weight: 400;
        height: 48px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
            box-shadow: 0px 3px 0px ${props.theme.colors.outlineAndPlaceholder};
            font-weight: 700;
        }
        &.filter-button {
            background-color: ${props.theme.colors.yellow};
            border-radius: 8px 0px 0px 8px;
            transition: 0.2s ease-in-out;
            &:hover {
                background: ${props.theme.colors.darkerYellow};
                box-shadow: 0px 3px 0px ${props.theme.colors.darkYellow};
                font-weight: 700;
            }
        }
        &.additional-search-button {
            background-image: none;
            width: 100%;
            border-radius: 8px;
            color: ${props.theme.colors.white};
            margin-top: 16px;
            height: 44px;
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.reset {
                background-color: ${props.theme.colors.white};
                color: ${props.theme.colors.black};
                border: 1px solid ${props.theme.colors.black};
            }
            &.undo {
                background-color: transparent;
                color: ${props.theme.colors.black};
                height: fit-content;
            }
        }
    `}
`;

const SearchIcon = styled(searchIcon)`
    ${(props) => css`
        &.white-search {
            color: ${props.theme.colors.white};
            &.smaller {
                margin-right: 8px;
                height: 18px;
                width: 18px;
            }
            &.mobile {
                position: absolute;
                color: ${props.theme.colors.outlineAndPlaceholder};
                margin: 16px 0 0 8px;
            }
        }
    `}
`;

export const BusinessList = () => {
    const urlParams = useParams();
    const [companies, setCompanies] = useState<CompanyListItem[]>([]);
    const [shownCoordinates, setShownCoordiantes] = useState<CompanyListItem>();
    const { state } = useLocation();
    const [t] = useTranslation();
    const { screenViewPort, bannersList, bannersFilteredByTopic } = useSelector((state: IState) => state.global);
    const { searchFilters } = useSelector((state: IState) => state.companies);
    const { topic } = useSelector((state: IState) => state.page);
    const [listMapToggle, setListMapToggle] = useState<{ list: boolean; map: boolean }>({ list: true, map: false });
    const [searchInput, setSearchInput] = useState<string>('');
    const [searchInputSubmit, setSearchInputSubmit] = useState<string>('');
    const [hover, setHover] = useState(false);
    const [city, setCity] = useState<string>('');

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (state?.companies) {
            return;
        }
        const filters: CompanySearchFilters = {};
        if (urlParams.categoryId && !urlParams.topicId) {
            filters.category = urlParams.categoryId;
        }
        if (urlParams.categoryId && urlParams.topicId) {
            filters.topic = urlParams.topicId;
        }

        if (urlParams.city) {
            if (searchFilters.city.length > 0) {
                setCity(searchFilters.city[Number(urlParams.city) - 1].city);
            }
            filters.city = urlParams.city;
        }

        getSearchCompanies(1, filters).then((res) => {
            setCompanies(res.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilters, urlParams.city]);

    useEffect(() => {
        if (state?.companies) {
            setCompanies(state.companies);
        }
    }, [state?.companies]);

    const cardsInView = (card: CompanyListItem) => {
        setShownCoordiantes(card);
    };

    const bannersSlice = () => {
        let currentSlice: BannerItem[] = [];

        if (bannersFilteredByTopic.WBS.length > 0) {
            currentSlice.push(bannersFilteredByTopic.WBS[0]);
        }

        currentSlice.push(...bannersList.WBM);

        return currentSlice;
    };

    const bannersSliceByIndex = (index: number): BannerItem[] => {
        if (screenViewPort === 'desktop') {
            if (index % 3 === 0) {
                return bannersSlice().slice(index, index + 3);
            } else {
                return [];
            }
        }
        switch (index) {
            case 0:
                return bannersSlice().slice(0, 1);
            case 3:
                return bannersSlice().slice(1, 2);
            case 6:
                return bannersSlice().slice(2, 3);
            case 9:
                return bannersSlice().slice(3, 4);
            case 12:
                return bannersSlice().slice(4, 5);
            case 15:
                return bannersSlice().slice(5, 6);
            default:
                return [];
        }
    };

    const capitalize = (str: string) => {
        if (str) {
            const string = str.toLowerCase();
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return;
    };

    const buildFilters = (query: string) => {
        let additionalFilter = { key: '', value: '' };

        if (urlParams.categoryId && !urlParams.topicId) {
            additionalFilter = { key: 'category', value: urlParams.categoryId };
        }
        if (urlParams.categoryId && urlParams.topicId && !urlParams.headId) {
            additionalFilter = { key: 'topic', value: urlParams.topicId };
        }
        if (urlParams.headId) {
            additionalFilter = { key: 'head', value: urlParams.headId };
        }

        return {
            query: query || '',
            [additionalFilter.key]: additionalFilter.value,
        };
    };

    const searchForCompanies = (query: string) => {
        getSearchCompaniesList(1, buildFilters(query)).then((res) => {
            const state = { companies: res.data };
            navigate(location.pathname, { state });
        });
    };

    return (
        <>
            <BusinessListPage>
                <Helmet>
                    <title>
                        {topic.name} {city}
                    </title>
                </Helmet>
                <HeaderWrapper>
                    <Header>
                        {capitalize(topic.name)} {city}
                    </Header>
                    <InputWrap
                        onSubmit={(e) => {
                            e.preventDefault();
                            searchForCompanies(searchInput);
                        }}
                    >
                        <InputItem
                            id="article_search"
                            class="green"
                            placeholder={t('enter_a_search_word')}
                            value={searchInput}
                            onChange={setSearchInput}
                        />
                        <Button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                setSearchInputSubmit(searchInput);
                            }}
                        >
                            <SearchIcon className="white-search" />
                        </Button>
                    </InputWrap>
                </HeaderWrapper>
            </BusinessListPage>
            <BusinessListPage>
                <CardContainer>
                    {screenViewPort !== 'desktop' && (
                        <ButtonContainer>
                            <FilterWrap>
                                <AdditionalFilters query={searchInput} />
                            </FilterWrap>
                            <ListMapWrap>
                                <ListMapButton
                                    className={classNames('list', {
                                        active: listMapToggle.list,
                                    })}
                                    onClick={() => setListMapToggle({ list: true, map: false })}
                                >
                                    {t('list')}
                                </ListMapButton>
                                <ListMapButton
                                    className={classNames('map', {
                                        active: listMapToggle.map,
                                    })}
                                    onClick={() => setListMapToggle({ list: false, map: true })}
                                >
                                    {t('map')}
                                </ListMapButton>
                            </ListMapWrap>
                            <Line />
                        </ButtonContainer>
                    )}
                    {screenViewPort === 'desktop' && <AdditionalFilters query={searchInput} />}
                    {(screenViewPort === 'desktop' || listMapToggle.list) && (
                        <Hero>
                            {companies.map((company, index) => (
                                <Wrapper key={index}>
                                    <BusinessListCard key={index} company={company} setCardInView={cardsInView} />
                                    {bannersSliceByIndex(index).length > 0 && (
                                        <AdsWrapper>
                                            {bannersSliceByIndex(index).map((banner) => {
                                                return (
                                                    <AdCardStyled
                                                        key={banner.id}
                                                        adUrl={banner.image}
                                                        adHref={banner.name}
                                                        customDimensions={{
                                                            height: '95%',
                                                            width: '95%',
                                                            maxHeight: '200px',
                                                        }}
                                                        fullWidth={true}
                                                    />
                                                );
                                            })}
                                        </AdsWrapper>
                                    )}
                                </Wrapper>
                            ))}
                            <Pagination setCompanies={setCompanies} />
                        </Hero>
                    )}
                    <>
                        <SideBar
                            className={classNames(
                                { hide: !listMapToggle.map && screenViewPort !== 'desktop' },
                                { 'mobile-size': screenViewPort !== 'desktop' }
                            )}
                        >
                            <Wrap>
                                <LeafletMap
                                    coordinates={screenViewPort === 'desktop' ? shownCoordinates?.address : undefined}
                                    companies={
                                        screenViewPort !== 'desktop' && listMapToggle.map ? companies : undefined
                                    }
                                />
                            </Wrap>
                        </SideBar>
                    </>
                    {urlParams.topicId && urlParams.categoryId && (
                        <RelatedCities
                            category={urlParams.categoryId}
                            topic={urlParams.topicId}
                            cities={searchFilters.city}
                        />
                    )}
                </CardContainer>
            </BusinessListPage>
        </>
    );
};
