import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';

import globalReducer, { IGlobalState } from './global.slice';
import languageReducer from './language/language.slice';
import categoriesReducer from './categories/categories.slice';
import companiesReducer from './companies/companies.slice';
import pageReducer from './page/page.slice';

import { ILanguageState } from './language/types';
import { ICategoriesState } from './categories/types';
import { ICompaniesState } from './companies/types';
import { IPageState } from './page/types';

export interface IState {
    global: IGlobalState;
    language: ILanguageState;
    categories: ICategoriesState;
    companies: ICompaniesState;
    page: IPageState;
}

const reducer = combineReducers({
    global: globalReducer,
    language: languageReducer,
    categories: categoriesReducer,
    companies: companiesReducer,
    page: pageReducer,
});

export const store = configureStore({
    reducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
