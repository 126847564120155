import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ROUTE_NAMES } from './constants/routes';
import { setBannersList, setScreenViewPort, switchBannerPositions } from './store/global.slice';
import { LandingPage } from './pages/LandingPage/LandingPage';
import { ArticlesPage } from './pages/ArticlesPage/ArticlesPage';
import styled, { ThemeProvider } from 'styled-components';
import { Header } from './components/Header/Header';
import { BusinessList } from './pages/BusinessList/BusinessList';
import { PageNotFound } from './pages/PageNotFound/PageNotFound';
import { MainPageLayout } from './pages/MainPageLayout/MainPageLayout';
import { IState } from './store';
import { CategoriesAndTopics } from './pages/CategoriesAndTopics/CategoriesAndTopics';
import { BusinessCardInner } from './components/InnerCardPage/BusinessCardInner';
import { EuProjectsPage } from './pages/EuProjectsPage/EuProjectsPage';
import { ArticleInner } from './components/ArticleInner/ArticleInner';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { getBanners } from './services/banners.service';
import { getCategoriesList } from './services/categories.service';
import { setCategoriesList } from './store/categories/categories.slice';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { setRecommendedCompanies } from './store/companies/companies.slice';
import { getRecommendedCompanies } from './services/company.service';

const AppWrapper = styled.div``;

export const App = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);

    useEffect(() => {
        getBanners().then((response) => {
            dispatch(setBannersList(response));
        });
        getCategoriesList().then((response) => {
            dispatch(setCategoriesList(response));
        });
        getRecommendedCompanies().then((res) => dispatch(setRecommendedCompanies(res)));
        calculateViewPort();
        window.addEventListener('resize', calculateViewPort);
        return () => {
            window.removeEventListener('resize', calculateViewPort);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const bannerSwitchInterval = setInterval(() => {
            dispatch(switchBannerPositions());
        }, 10000);
        return () => {
            clearInterval(bannerSwitchInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList.WBM]);

    const calculateViewPort = () => {
        const windowWidth: number = window.outerWidth;
        if (windowWidth >= 768 && windowWidth < 1024) {
            dispatch(setScreenViewPort('tablet'));
            return;
        }
        if (windowWidth < 768) {
            dispatch(setScreenViewPort('mobile'));
            return;
        }
        if (windowWidth >= 1024) {
            dispatch(setScreenViewPort('desktop'));
        }
    };

    return (
        <ThemeProvider theme={{ screen: { [screenViewPort]: true } }}>
            <AppWrapper>
                <ScrollToTop />
                <Header />
                <Routes>
                    <Route element={<MainPageLayout />}>
                        <Route path={ROUTE_NAMES.HOME} element={<LandingPage />} />
                        <Route path={ROUTE_NAMES.EU_PROJECTS} element={<EuProjectsPage />} />
                        <Route path={ROUTE_NAMES.ARTICLE_PAGE}>
                            <Route index element={<ArticlesPage />} />
                            <Route path={ROUTE_NAMES.ARTICLE_INNER}>
                                <Route index element={<ArticleInner />} />
                            </Route>
                        </Route>
                        <Route path={ROUTE_NAMES.CATEGORIES_AND_TOPICS}>
                            <Route index element={<CategoriesAndTopics />} />
                        </Route>
                        <Route path={ROUTE_NAMES.BUSINESS_LIST}>
                            <Route index element={<BusinessList />} />
                            <Route path={ROUTE_NAMES.BUSINESS_LIST_CATEGORY}>
                                <Route index element={<BusinessList />} />
                                <Route path={ROUTE_NAMES.BUSINESS_LIST_TOPIC}>
                                    <Route index element={<BusinessList />} />
                                    <Route path={ROUTE_NAMES.BUSINESS_LIST_CITY}>
                                        <Route index element={<BusinessList />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path={ROUTE_NAMES.COMPANY_CARD} element={<BusinessCardInner />} />
                    </Route>
                    <Route path={ROUTE_NAMES.PAGE_NOT_FOUND} element={<PageNotFound />} />
                </Routes>
                <CookieConsent buttonText={t('accept')}>{t('cookie_consent')}</CookieConsent>
            </AppWrapper>
        </ThemeProvider>
    );
};
