import { Topic } from '../../types/api';

export interface IPageState {
    topic: Topic;
}

export const initialPageState: IPageState = {
    topic: {
        id: 0,
        name: '',
        heads: [],
    },
};
