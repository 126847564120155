import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { IState } from '../../store';
import { City } from '../../types/api';

const TopicsContainer = styled.div`
    ${(props) => css`
        margin: 16px 0;
        background-color: ${props.theme.colors.white};
        border-radius: 8px;
        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            align-items: flex-start;
        `}
    `}
`;
const HeaderWrap = styled.div``;
const Header = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.005em;
        margin-right: 24px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const TopicsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 2px;
`;
const Rub = styled.div`
    ${(props) => css`
        margin-right: 24px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: ${props.theme.colors.lighterBlack};
        ${props.theme.screen.mobile &&
        css`
            margin-top: 8px;
        `}
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    `}
`;

type Props = {
    cities: City[];
    category: string;
    topic: string;
};

export const RelatedCities = (props: Props) => {
    const navigate = useNavigate();

    const navigateToTopicCity = (
        categoryId: string,
        topicId: string,
        city: City,
        e: React.MouseEvent<HTMLDivElement>
    ) => {
        e.stopPropagation();
        navigate(`/imoniu-sarasas/${categoryId}/${topicId}/${city.id}`);
    };

    return (
        <TopicsContainer>
            <HeaderWrap>
                <Header>{t('cities')}:</Header>
            </HeaderWrap>
            {props.cities.length > 0 && (
                <TopicsWrap>
                    {props.cities.map((city: City, i) => {
                        return (
                            <Rub onClick={(e) => navigateToTopicCity(props.category, props.topic, city, e)} key={i}>
                                {city.city}
                            </Rub>
                        );
                    })}
                </TopicsWrap>
            )}
        </TopicsContainer>
    );
};
